var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('template-layout',[_c('template',{slot:"drawers"}),_c('template',{slot:"print"},[(_vm.filters.date)?[(_vm.filters.date.lte)?_c('span',{staticClass:"headline"},[_vm._v("Cosecha - "+_vm._s(_vm._f("capitalize")(_vm._f("moment")(_vm.filters.date.gte,'dddd D MMMM')))+" al "+_vm._s(_vm._f("capitalize")(_vm._f("moment")(_vm.filters.date.lte,'dddd D MMMM'))))]):_c('span',{staticClass:"headline"},[_vm._v("Cosecha - "+_vm._s(_vm._f("capitalize")(_vm._f("moment")(_vm.filters.date,'dddd D MMMM'))))])]:_vm._e(),_vm._l((_vm.harvestByCategory),function(value,key){return _c('div',{key:key},[_c('h2',{staticClass:"body-1 py-4"},[_vm._v(_vm._s(key)+" "),_c('span',{staticClass:"grey--text"},[_vm._v("("+_vm._s(value.length)+")")])]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((value),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.quantity)+" "+_vm._s(item.name)+" ("+_vm._s(item.presentation)+")")])])}),0)]},proxy:true}],null,true)})],1)})],2),_c('template',{slot:"content"},[_c('v-container',[_c('v-toolbar',{staticClass:"mb-2",attrs:{"flat":"","dense":"","color":"transparent","height":"60"}},[(_vm.filters.date)?_c('v-toolbar-title',[(_vm.filters.date.lte)?_c('span',{staticClass:"headline"},[_vm._v("Cosecha - "+_vm._s(_vm._f("capitalize")(_vm._f("moment")(_vm.filters.date.gte,'dddd D MMMM')))+" al "+_vm._s(_vm._f("capitalize")(_vm._f("moment")(_vm.filters.date.lte,'dddd D MMMM'))))]):_c('span',{staticClass:"headline"},[_vm._v("Cosecha - "+_vm._s(_vm._f("capitalize")(_vm._f("moment")(_vm.filters.date,'dddd D MMMM'))))])]):_vm._e(),_c('v-toolbar-title',[_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-calendar-edit")])],1)]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","range":"","first-day-of-week":1,"locale":"es-AR","event-color":"green lighten-1"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1),_c('v-spacer'),_c('v-toolbar-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.print}},on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}])},[_c('span',[_vm._v("Imprimir")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{staticClass:"elevation-0",attrs:{"multiple":""}},_vm._l((_vm.harvestByCategory),function(value,key){return _c('v-expansion-panel',{key:key},[_c('v-expansion-panel-header',{staticClass:"body-1"},[_vm._v(_vm._s(key)+" "),_c('span',{staticClass:"grey--text"},[_vm._v("("+_vm._s(value.length)+")")])]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"hide-default-header":"","hide-default-footer":"","headers":_vm.headers,"items":value,"loading":_vm.isLoading,"items-per-page":-1,"no-data-text":"No hay productos para cosechar en la fecha seleccionada","mobile-breakpoint":0,"disable-sort":true},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var items = ref.items;
var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm._f("moment")(group,'calendar', 'now', { lastDay : '[Ayer]', sameDay : '[Hoy]', nextDay : '[Mañana]', lastWeek : '[Último] dddd', nextWeek : '[Próximo] dddd', sameElse : 'DD/MM/YYYY' }))+" ")]),_c('v-btn',{staticClass:"pa-0 ma-0 hidden-print-only",attrs:{"icon":""},on:{"click":toggle}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" "+_vm._s(item.name)+" ("+_vm._s(item.presentation)+") ")]}}],null,true)})],1)],1)}),1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }