<template>
    <template-layout>
        <template slot="drawers">
        </template>

        <template slot="print">
            <template v-if="filters.date">
                <span v-if="filters.date.lte" class="headline">Cosecha - {{ filters.date.gte | moment('dddd D MMMM') | capitalize }} al {{ filters.date.lte | moment('dddd D MMMM') | capitalize }}</span>
                <span v-else class="headline">Cosecha - {{ filters.date | moment('dddd D MMMM') | capitalize }}</span>
            </template>

            <div v-bind:key="key" v-for="(value, key) in harvestByCategory">
                <h2 class="body-1 py-4">{{ key }} <span class="grey--text">({{ value.length }})</span></h2>

                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr v-for="item in value" :key="item.id">
                                <td>{{ item.quantity }} {{ item.name }} ({{ item.presentation }})</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </template>

        <template slot="content">
            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title v-if="filters.date">
                        <span v-if="filters.date.lte" class="headline">Cosecha - {{ filters.date.gte | moment('dddd D MMMM') | capitalize }} al {{ filters.date.lte | moment('dddd D MMMM') | capitalize }}</span>
                        <span v-else class="headline">Cosecha - {{ filters.date | moment('dddd D MMMM') | capitalize }}</span>
                    </v-toolbar-title>

                    <v-toolbar-title>
                        <v-menu
                            ref="dateMenu"
                            v-model="dateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon><v-icon>mdi-calendar-edit</v-icon></v-btn>
                            </template>

                            <v-date-picker
                                v-model="dateRange"
                                no-title
                                range
                                :first-day-of-week="1"
                                locale="es-AR"
                                event-color="green lighten-1"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon @click="print" v-on="on"><v-icon>mdi-printer</v-icon></v-btn>
                            </template>
                            <span>Imprimir</span>
                        </v-tooltip>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12">
                        <v-expansion-panels multiple class="elevation-0">
                            <v-expansion-panel v-bind:key="key" v-for="(value, key) in harvestByCategory">
                                <v-expansion-panel-header class="body-1">{{ key }} <span class="grey--text">({{ value.length }})</span></v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <v-data-table
                                        hide-default-header
                                        hide-default-footer
                                        :headers="headers"
                                        :items="value"
                                        :loading="isLoading"
                                        :items-per-page="-1"
                                        no-data-text="No hay productos para cosechar en la fecha seleccionada"
                                        :mobile-breakpoint="0"
                                        :disable-sort="true"
                                    >
                                        <template v-slot:group.header="{group, items, toggle}">
                                            <td :colspan="headers.length">
                                                <span class="title">
                                                    {{ group | moment('calendar', 'now', {
                                                        lastDay : '[Ayer]',
                                                        sameDay : '[Hoy]',
                                                        nextDay : '[Mañana]',
                                                        lastWeek : '[Último] dddd',
                                                        nextWeek : '[Próximo] dddd',
                                                        sameElse : 'DD/MM/YYYY'
                                                    }) }}
                                                </span>
                                                <v-btn class="pa-0 ma-0 hidden-print-only" icon @click="toggle"><v-icon>mdi-chevron-down</v-icon></v-btn>
                                            </td>
                                        </template>

                                        <template v-slot:item.name="{ item }">
                                            {{ item.quantity }} {{ item.name }} ({{ item.presentation }})
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';

    export default {
        components: {
            TemplateLayout
        },

        data() {
            return {
                isLoading: false,

                dateMenu: false,
                dateRange: [],

                defaultFilters: {
                    date: this.$moment().format('YYYY-MM-DD')
                },
                filters: {},

                headers: [
                    { text: 'Producto', value: 'name' }
                ]
            };
        },

        computed: {
            harvest() {
                return this.$store.getters['harvest/getAll']();
            },

            harvestByCategory() {
                const key = 'category';

                let groups = this.harvest.reduce((rv, x) => {
                    const group = x[key];
                    (rv[group] = rv[group] || []).push(x);

                    return rv;
                }, {});

                return groups;
            }
        },

        watch: {
            dateRange(val) {
                let dates = [ ...val ];
                dates.sort();

                if(dates.length === 2) {
                    this.filters.date = {
                        gte: dates[0],
                        lte: dates[1]
                    };
                }
                else {
                    this.filters.date = dates[0];
                }
            },

            filters: {
                handler(val) {
                    this.updateRouteQuery();

                    this.fetchHarvest();
                },

                deep: true
            }
        },

        mounted() {
            this.filters = JSON.parse(JSON.stringify(this.defaultFilters));
            this.updateFiltersFromQuery();

            this.fetchHarvest();
        },

        methods: {
            updateFiltersFromQuery() {
                let q = { ...this.$route.query };

                for(let key of Object.keys(this.filters)) {
                    if(q[key]) {
                        this.filters[key] = q[key];
                    }
                }
            },

            updateRouteQuery() {
                let q = {};

                if(this.filters) {
                    let keys = Object.keys(this.filters);
                    for(const k of keys) {
                        if(this.filters[k] !== this.defaultFilters[k]) {
                            q[k] = this.filters[k];
                        }
                    }
                }

                this.$router.replace({ name: 'harvestList', query: q }).catch(() => {});
            },

            async fetchHarvest() {
                this.isLoading = true;

                let filters = { ...this.filters };
                let result = await this.$store.dispatch('harvest/fetchAll', filters);

                this.totalOrders = result.total;
                this.isLoading = false;
            },

            print() {
                window.print();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-data-table {
        ::v-deep .v-row-group__header td {
            border: none !important;
        }
    }

    @media print {
        .v-data-table {
            ::v-deep table {
                tbody {
                    tr {
                        td.text-start {
                            height: 20px !important;
                            border-bottom: 1px solid gray !important;
                        }
                    }
                }
            }
        }
    }
</style>
